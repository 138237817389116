export interface Module {
  icon: string;
  description: string;
  goal: string;
  module_id: string;
  id: string;
  name: string;
  weeks_length: number; // TODO: change to number_of_parts when will be changed in cms
  teaser?: string;
  mainRecommendation?: boolean;
  blocked_for_selection: boolean;
}

export interface Part {
  part_number: number;
  part_goal: string;
  module: string;
  order_string: string;
  part_description: string;
  part_id: number;
}

export interface CMSResponse<T> {
  id: number;
  attributes: {
    createdAt: string;
    publishedAt: string;
    updatedAt: string;
  } & T;
}

export type CMSResponseType<T, isArray = false> = {
  data: isArray extends true ? CMSResponse<T>[] : CMSResponse<T>;
  meta: {
    pagination: {
      total: number;
    };
  };
};

export enum MemberStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  BLOCKED = 3,
  UNVERIFIED = 4,
  VERIFIED = 5,
  VERIFICATION_PENDING = 6,
  DISABLED = 7,
}

export enum AccountStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  PAUSED = 3,
  REACTIVATION_REQUESTED = 4,
}

export enum Gender {
  MALE = "male",
  FEMALE = "female",
}

export enum MemberFunnels {
  TIERS_V1 = 1,
  CHURCH = 2,
}

export interface Member {
  id: number;
  expert: string;
  uuid: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  age: number;
  gender: Gender;
  messaging_platform: number;
  messaging_verification_code: string;
  messaging_preferred_platform: string;
  status: MemberStatus;
  phone_number: string;
  conversation_id: string;
  chat_id: string;
  messaging_platforms: number[];
  calendly_additional_notes?: string;
  module?: string;
  schedule_link: string;
  app_status: AppStatuses;
  partner?: Member;
  primary_member?: number;
  created: string;
  picture: string;
  subscribed: boolean;
  extra_details?: ExtraMemberDetails;
}

interface ExtraMemberDetails {
  registration: REGISTRATION_TYPE;
}

export enum REGISTRATION_TYPE {
  V1 = "v1",
  V2 = "v2",
}

export enum AppStatuses {
  INACTIVE = 1,
  ONBOARDING = 2,
  ACTIVE = 3,
}

export enum CurrentPartStatuses {
  STARTED = 1,
  WAITING_FOR_REVIEW = 2,
  REVIEW_SENT = 3,
  WAITING_FOR_REVIEW_IN_SESSION = 4,
  FEEDBACK_RECEIVED_IN_SESSION = 5,
  INCOMPLETE = 6,
  SKIPPED = 7,
  ASSIGNED = 8,
  ON_HOLD = 9,
}

export enum MemberModuleStatus {
  NOT_STARTED = 1,
  PARTIALLY_COMPLETED = 2,
  COMPLETED = 3,
}

export enum PlanCategory {
  GROUP = 1,
  INDIVIDUAL = 2,
  MATCHING = 3,
}

export interface MemberModule {
  selected: boolean;
  current_part: number;
  program: { uuid: string; cms_id: string };
  uuid: string;
  current_part_status: CurrentPartStatuses;
  status: MemberModuleStatus;
}

export enum GWPlatforms {
  TYPEFORM = "TYPEFORM",
  VIDEOASK = "VIDEOASK",
  WISTIA = "WISTIA",
  AUDIO = "AUDIO",
}

export const platformMapping = {
  [GWPlatforms.TYPEFORM]: 1,
  [GWPlatforms.VIDEOASK]: 2,
  [GWPlatforms.WISTIA]: 3,
};

export interface WistiaDetails {
  title: string;
  thumbnail_url: string;
  duration: number;
}

export interface Growthwork {
  part_goal?: string;
  id: number;
  baselink: string;
  gw_description: string;
  gw_id: string;
  gw_short_title: string;
  length_minutes: string;
  platform: GWPlatforms;
  gw_order: number;
  relation_cms_id: number;
  expert?: any;
  image?: string;
}

export enum MemberGrowthWorkStatus {
  GENERATING = 1,
  CREATED = 2,
  COMPLETED = 3,
  REVIEWED = 4,
  EXPIRED = 5,
}

export interface MemberGrowthWork {
  id: number;
  growthwork_link: {
    uuid: string;
    growthwork: string;
    module: string;
    platform: number;
    cms_id: string;
  };
  member: string;
  expert: string;
  created: string;
  updated: string;
  uuid: string;
  status: MemberGrowthWorkStatus;
  url: string;
  url_hash: string;
  module?: string;
  gw_cms_id: string;
  result: any;
}

export type ModuleRelation = {
  part_order: number;
  gw_order: number;
  module: CMSResponseType<Module>;
  part: CMSResponseType<Part>;
  gw: CMSResponseType<Growthwork>;
  image: CMSResponseType<{ url: string }, false>;
  total: number;
  id: number;
};

export type MemberGrowthworkUnion = MemberGrowthWork & Growthwork;

export interface Expert {
  created: string;
  updated: string;
  uuid: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  matrix_user: string;
  matrix_pass: string;
  phone_number: string;
}

export enum SessionType {
  CHAT = 1,
  HABIT_TRACKING = 2,
  CHECKIN = 3,
  WELCOME = 4,
  INDIVIDUAL = 5,
  GROUP = 6,
  MATCHING = 9,
}

export const FirstMeetingTypes = [SessionType.WELCOME, SessionType.MATCHING];

export enum SessionStatus {
  ONGOING = 1,
  ENDED = 2,
  SCHEDULED = 3,
  CANCELED = 4,
  RESCHEDULED = 5,
  NOT_STARTED = 6,
}

export interface Session {
  created: string;
  next_session: string;
  next_session_invitee_id: string;
  next_session_event_id: string;
  type: SessionType;
  session_link?: string;
  uuid: string;
  status: SessionStatus;
}

export interface YouareOnYourWay {
  button: string;
  subtitle: string;
  summary: string;
  title: string;
  link: CMSResponseType<{ link: string }>;
  image: CMSResponseType<{ url: string }, true>;
}

export interface TaskCompletedScreen {
  title: string;
  subtitle: string;
  button: string;
  buttonId: string;
  type: GWPlatforms | "all" | "module";
}

export type ModuleMatching = {
  module_id: string;
  matching_score: number;
};

export interface Banner {
  title: string;
  text: string;
  color: string;
  state: {
    field: string;
    model: "module" | "member" | "assigned_part";
    value: any;
    featureFlag?: string;
  };
  icon: string;
  query_param?: string;
  prioritized?: boolean;
  id: number;
}

export interface Drawer {
  title: string;
  text: string;
  button: string;
  buttonId: string;
  icon: string;
  query_param: string;
  secondary_button?: string;
  secondary_button_id?: string;
}

export interface AssignedPart {
  num: number;
  status: CurrentPartStatuses;
  uuid: string;
}

export interface CarouselContent {
  title: string;
  description: string;
  button_text: string;
  background_color: string;
  step: number;
  image: string;
}

export interface ModulePartGrowthworks {
  [key: string]: { [key: string]: Growthwork[] };
}

export interface ExpertResponse {
  name: string;
  mgmt_uid: string;
}

export interface ExpertCmsResponse extends ExpertResponse {
  avatar?: CMSResponseType<{ url: string }>;
}

export interface ExpertCms extends ExpertResponse {
  avatar?: string;
}

export interface SessionEvent {
  type: SessionType;
  allowed_tiers: string;
  short_title: string;
  duration: string;
  color: string;
  image: string;
  book_session_text: string;
  allowed_plan_category: string;
}

export interface SessionLink {
  uuid: string;
  link: string;
  type: SessionType;
}

export interface MatchingLink {
  is_matching: boolean;
  is_matching_session_done: boolean;
}

export interface FirstPartCompleted {
  is_first_part_completed: boolean;
}

export interface OnboardingStatus {
  partner_added: boolean | null;
  partner_added_skipped?: boolean;
  welcome_session: boolean;
  welcome_session_skipped?: boolean;
  matching_session: boolean;
  matching_session_skipped?: boolean;
}

export interface AddPartnerBody {
  primary_member: {
    email: string;
    phone_number: string;
    uuid: string;
  };
  partner: {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
  };
}

export interface ExpertSession {
  expertId: string;
  sessionId: string;
}

export interface SubscriptionDetailsBase {
  tier: number;
  name: string;
  title: string;
}

export interface SubscriptionDetailsCms extends SubscriptionDetailsBase {
  items_included: string;
}

export interface SubscriptionDetails extends SubscriptionDetailsBase {
  items_included: string[];
}

export interface Subscription {
  duration: number;
  price: number;
  started_date: string;
  next_billing_date: string;
  renew_date?: string;
  cms_id?: string;
}

export interface Account {
  status?: AccountStatus;
  stripe_customer_id?: string;
  show_subscription_update_option?: boolean;
}

export enum BillingCycleType {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
  YEAR = 4,
}

export interface MemberPlanDetails {
  plan_name: string;
  price: number;
  duration: number;
  started_at: string;
  next_billing_date: string;
  cms_id: string;
  category: PlanCategory;
  billing_cycle_value: number;
  billing_cycle_type: BillingCycleType;
}

export interface LibraryGrowthworkParts {
  id: number;
  part_order: number;
  part_goal: string;
  part_description: string;
  growthworks: LibraryGrowthwork[];
}

export interface LibraryGrowthwork extends Growthwork {
  is_new: boolean;
}

export interface SubscriptionStatusResponse {
  is_subscription_paused: boolean;
  pause_until_date: number | null;
}

export enum FeedbackTypes {
  SCHEDULING = 1,
  VIDEO = 2,
  QUESTIONNAIRE = 3,
  VIDEO_JOURNAL = 4,
}

export type FeedbackPostParams =
  | { skipped: true; member: string; type: FeedbackTypes }
  | {
      rating: number;
      free_text: string;
      type: FeedbackTypes;
      member: string;
      event_fields?: object;
    };

export interface MemberFeedback {
  member: string;
  uuid: string;
  type: FeedbackTypes;
  rating: number;
  free_text?: string;
  skipped: boolean;
}

export interface SurveyResponse {
  id: number;
  member: string;
  survey_type: number;
  created: string;
  updated: string;
  uuid: string;
  survey_id: string;
  raw_data_id: string;
}

export enum JournalEntryResponseType {
  TEXT = "text",
  VIDEO = "video",
  AUDIO = "audio",
}

export interface JournalEntryResult {
  type: JournalEntryResponseType;
  response: string;
}

export interface PathwayRecommendation {
  type: PathwayRecommendationType;
  recommended_pathways: number[];
}

export enum PathwayRecommendationType {
  DEFAULT = "default",
  PERSONALIZED = "personalized",
}
